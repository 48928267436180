import { untilWritableState } from '$lib/utilities/core/untilWritableState';
import queryClient from '$lib/utilities/tanstack/queryClient';
import {
	userPreferencesState,
	type UserPreferenceKeys,
	type UserPreferencesData,
} from '$lib/utilities/userPreferences/consts';
import { createUserPreferenceQueryKey } from '$lib/utilities/userPreferences/tanstack/createUserPreferenceQueryKey';
import { Preferences } from '@capacitor/preferences';
import toast from 'svelte-french-toast';

export async function updateUserPreferenceDirectly<TKey extends UserPreferenceKeys>(
	key: TKey,
	value: UserPreferencesData[TKey],
) {
	// Optimistically set the user preference to the new value
	queryClient.setQueryData(createUserPreferenceQueryKey(key), () => value);
	await Preferences.set({ key, value: JSON.stringify(value) }).catch((error) => {
		console.error(`Failed to set user preference ${key}:`, error);

		toast.error(`Failed to set user preference for ${key}`, {
			duration: 10000,
		});
	});

	queryClient.invalidateQueries({
		queryKey: createUserPreferenceQueryKey(key),
	});
}

/**
 * Update the user preference for the given key to the new value.
 *
 * @param key The key of the user preference to update
 * @param value The new value to set for the user preference
 */
export async function updateUserPreference<TKey extends UserPreferenceKeys>(
	key: TKey,
	value: UserPreferencesData[TKey],
) {
	await untilWritableState(userPreferencesState, 'ready');
	await updateUserPreferenceDirectly(key, value);
}
