import type { AvailableLanguageTag } from '$lib/paraglide/runtime';
import { writable } from 'svelte/store';

export type UserPreferencesData = {
	ACTIVE_SUBSCRIPTION: CdvPurchase.VerifiedPurchase | undefined;
	INTRODUCTION_COMPLETED: boolean;
	DAILY_REMINDER_NOTIFICATIONS: boolean | null;
	APP_THEME: 'default' | 'dark';
	GOAL_PANEL_TIP_MODAL_DISMISSED: boolean;
	LANGUAGE: AvailableLanguageTag | undefined;
	// Development
	DEV__IS_TEST_SUBSCRIPTION_ACTIVE: boolean;
	DEV__IS_USER_DEVELOPMENT_MODE: boolean;
};
export const USER_PREFERENCE_DEFAULTS: UserPreferencesData = {
	ACTIVE_SUBSCRIPTION: undefined,
	INTRODUCTION_COMPLETED: false,
	DAILY_REMINDER_NOTIFICATIONS: null,
	APP_THEME: 'default',
	GOAL_PANEL_TIP_MODAL_DISMISSED: false,
	LANGUAGE: undefined,
	// Development
	DEV__IS_TEST_SUBSCRIPTION_ACTIVE: false,
	DEV__IS_USER_DEVELOPMENT_MODE: false,
} as const;

export type UserPreferenceKeys = Partial<keyof UserPreferencesData>;

type userPreferenceStates = 'initialising' | 'ready' | 'uninitialised';
export const userPreferencesState = writable<userPreferenceStates>('uninitialised');
